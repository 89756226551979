import { authLogger } from "@/debug/auth";
import { getUserType } from "@/functions/getUserType";
import { createClient } from "@/supabase/utils/client";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

const getUser = async () => {
  const supabase = createClient();
  try {
    const user = await supabase.auth.getUser();
    return user;
  } catch (error) {
    // @ts-ignore
    console.error("Error fetching getUser", error.message);
    return null;
  }
};

function checkAuth(router: AppRouterInstance) {
  return false; // 미들웨에어서 처리하므로 사용하지 않음
  (async () => {
    const userType = getUserType();
    if (userType === "anonymous") {
      authLogger("checkAuth", "anonymous - go /home");
      router.push("/home");
    } else {
      const user = await getUser();
      if (user?.data.user === null) {
        authLogger("checkAuth", "user null - go /welcome");
        router.push("/welcome");
      } else {
        authLogger("checkAuth", "user not null - go /home");
        router.push("/home");
      }
    }
  })();
}

export function useCheckRootAuth() {
  const router = useRouter();
  useEffect(() => {
    checkAuth(router);
  }, []);
  return null;
}

export function useCheckWelcomeAuth() {
  const router = useRouter();
  useEffect(() => {
    checkAuth(router);
  }, []);
  return null;
}

export function useCheckHomeAuth() {
  const router = useRouter();
  useEffect(() => {
    checkAuth(router);
  }, []);
  return null;
}
